
<template>
  <div class="home">
    <ul class="flexContent flex">
      <li class="leftData flex flex-column">
        <div class="takeNumber bk_b">
          <dv-border-box-10>
            <roomsTop roleType="0" />
          </dv-border-box-10>
        </div>
        <div class="sosAlarm bk_b">
          <dv-border-box-10>
             <div class="demo listPd">
              <takeCharge roleType="0" />
             </div>
          </dv-border-box-10>
        </div>

        <div class="sosAlarm bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <takeChargeLongTime roleType="0" />
            </div>
          </dv-border-box-10>
        </div>
      </li>
      <li class="eachartsCont flex flex-column flex-1">
        <div class="mapChart">
          <chinaMap showType="1" />
        </div>
        <div class="bar_charts">
          <barCharts showType="1" />
        </div>
      </li>
      <li class="rightData flex flex-column">
        <!-- 设备出租率 -->
        <div class="right_top1 bk_b">
          <dv-border-box-10>
              <roomsNum roleType="0" />
          </dv-border-box-10>
        </div>

        <!-- 出租率情况 -->
        <div class="right_2 bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <!-- <pmsNew  /> -->
               <enterDetails class="listPd" />
            </div>
          </dv-border-box-10>
        </div>
        <div class="right_3 bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <!-- <checkInType /> -->
               <checkInTime  roleType="0"/>
            </div>
          </dv-border-box-10>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import roomsTop from "../components/roomsTop2";
import roomsNum from "../components/roomsNum";
import chinaMap from "../components/chinaMap";
import barCharts from "../components/barCharts";
import checkInTime from "../components/checkInTime";
import takeChargeLongTime from "../components/takeChargeLongTime";
import enterDetails from "../components/enterDetails";
import takeCharge from "../components/takeCharge";

export default {
  // name: "Home",
  components: {
    roomsTop,
    takeCharge,
    takeChargeLongTime,

    chinaMap,
    barCharts,

    roomsNum,
    enterDetails,
    checkInTime,
  },

  data() {
    return {};
  },
  mounted() {
    // window.addEventListener("resize", () => {
    //   // this.myChart.resize();
    //   // this.lineEcharts.resize();
    // });
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/kanbanPage.scss";
</style>
