<template>
  <div class="demo">
    <div
      id="takeCharge"
      class="chartBar"
      :class="{ echartNoData: !hasFlag &&!showCharts}"
    ></div>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { hourlyElectricity } from "@/api";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_Font,
  lineSize,
  symbolSize_Font,
  symbolSize
} from "./font";

export default {
  props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  components: {
    CountTo,
  },
  data() {
    return {
      lineTimeInter: null,
      hasFlag: true,
      showCharts:false,
      lineData: {
        dataVal: [
          { name: "2020-10-14 09时", value: 8 * 255 },
          { name: "2020-10-14 10时", value: 8 * 200 },
          { name: "2020-10-14 11时", value: 8 * 168 },
          { name: "2020-10-14 12时", value: 8 * 103 },
          { name: "2020-10-14 13时", value: 8 * 43 },
          { name: "2020-10-14 14时", value: 8 * 36 },
          { name: "2020-10-14 15时", value: 8 * 56 },
          { name: "2020-10-14 16时", value: 8 * 89 },
          { name: "2020-10-14 17时", value: 8 * 118 },
          { name: "2020-10-14 18时", value: 8 * 105 },
          { name: "2020-10-14 19时", value: 8 * 103 },
          { name: "2020-10-14 20时", value: 8 * 124 },
          { name: "2020-10-14 21时", value: 8 * 168 },
          { name: "2020-10-14 22时", value: 8 * 175 },
          { name: "2020-10-14 23时", value: 8 * 185 },
          { name: "2020-10-15 00时", value: 8 * 190 },
          { name: "2020-10-15 01时", value: 8 * 204 },
          { name: "2020-10-15 02时", value: 8 * 236 },
          { name: "2020-10-15 03时", value: 8 * 289 },
          { name: "2020-10-15 04时", value: 8 * 327 },
          { name: "2020-10-15 05时", value: 8 * 327 },
          { name: "2020-10-15 06时", value: 8 * 327 },
          { name: "2020-10-15 07时", value: 8 * 315 },
          { name: "2020-10-15 08时", value: 8 * 289 },
        ],

        times: [
          "14日09时",
          "14日10时",
          "14日11时",
          "14日12时",
          "14日13时",
          "14日14时",
          "14日15时",
          "14日16时",
          "14日17时",
          "14日18时",
          "14日19时",
          "14日20时",
          "14日21时",
          "14日22时",
          "14日23时",
          "15日00时",
          "15日01时",
          "15日02时",
          "15日03时",
          "15日04时",
          "15日05时",
          "15日06时",
          "15日07时",
          "15日08时",
        ],
      },
    };
  },
  mounted() {
    this.takeCharge = this.$echarts.init(document.getElementById("takeCharge"));
    window.addEventListener("resize", () => {
      this.takeCharge.resize();
    });
    this.getLinesData();
    this.lineTimeInter && clearInterval(this.lineTimeInter);
    this.lineTimeInter = setInterval(() => {
      this.getLinesData();
    }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.lineTimeInter);
  },
  methods: {
    getOptions(data) {
      return {
        title: {
          text: "取电数分布",
          textStyle: {
            align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title2_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "5%",
          right: "8%",
          bottom: "10%",
          top: title2_Font() * 3,
          containLabel: true,
        },
        legend: {
          bottom: 0,
          itemWidth: w_Font(),
          itemHeight: h_Font(),
          textStyle: {
            color: "#fff",
            fontStyle: "normal",
            fontFamily: "微软雅黑",
            fontSize: x_Font(),
          },
        },
        xAxis: {
          splitLine: {
            show: false,
          },
          data: data.times,
          axisLabel: {
            color: "#fff",
            fontSize: x_Font(),
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          splitLine: {
            lineStyle: {
              type: "dashed",
              width: lineSize(),
            },
          },

          axisLabel: {
            fontSize: x_Font(),
            color: "#fff",
          },
        },
        series: [
          {
            name: "取电数",
            type: "line",
            data: data.dataVal,
            symbolSize: symbolSize_Font(),
            lineStyle: {
              width: line_Font(), //设置线条粗细
            },
            markPoint: {
             
              symbolSize: symbolSize(),
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    fontSize: x_Font(),
                  },
                },
              },
              data: [
                {
                  type: "max",name: "最大值" 
                  },
                {type: 'min', name: '最小值'}
              ],
            },
           
            itemStyle: {
              // color: "#00CC00",
              color: "#37a2da",
              fontSize: x_Font(),
            },
          },
        ],
      };
    },

    async getLinesData() {
      try {
        this.hasFlag = true;
        let res = await hourlyElectricity(this.roleType);
        if (res.data.code == "000000") {
          if (!res.data.data || res.data.data.length == 0) {
            // this.hasFlag = false;
          } else {
            this.showCharts=true
            this.lineData.dataVal = [];
            this.lineData.times = [];
            res.data.data.forEach((item) => {
              let t = item.time.split(" ");
              let d = t[0].split("-")[2];
              // d + "日" + 
              this.lineData.times.push(t[1] + "时");
              this.lineData.dataVal.push({
                name: item.time + "时",
                value: item.count,
              });
            });
          }
        }
      } catch (error) {
        this.hasFlag = false;
      }
      if (this.hasFlag&&this.showCharts) {
        this.initLine();
      }
    },
    initLine() {
      this.takeCharge.setOption(this.getOptions(this.lineData));
    },
    resize() {
      this.takeCharge.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}
</style>