<template>
    <div class=" demo">
      <div class="boxPd">
      <header>实时开房数</header>
     <div class="customer flex flex-column">
         <dv-decoration-9 style="height:14vh;width:80%;"  :dur="18">
            <count-to
              :start-val="oldTotal"
              :end-val="newTotal"
              :duration="3000"
            />
          </dv-decoration-9>
      </div>
      </div>
    </div>
</template>
<script>
import CountTo from "vue-count-to";
import { rentedCount } from "@/api";
export default {
   props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  components: {
    CountTo,
  },
  data() {
    return {
      oldTotal:0,
      newTotal:0
    };
  },

  created() {
    this.getElectricity();
    this.rentedCountInter = null;
    this.rentedCountInter = setInterval(() => {
      this.getElectricity();
    }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.rentedCountInter);
  },
  methods: {
    // 获取取电总数
    async getElectricity() {
      let res = await rentedCount(this.roleType);
      if(res.data.code=='000000'){
        this.newTotal=res.data.data?res.data.data:0
         setTimeout(() => {
           this.oldTotal= this.newTotal
         }, 2000);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";

.demo {
  header {
    font-size: 1.7vh;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }
  .customer {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: PingFang SC;
    font-weight: bold;
    height: 15vh;
    color: rgba(255, 255, 255, 1);
    span {
      font-size: 4vh;
      position: relative;
      z-index: 999;
    }

  }
}
</style>
