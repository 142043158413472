var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: " demo" }, [
    _c("div", { staticClass: "boxPd" }, [
      _c("header", [_vm._v("实时开房数")]),
      _c(
        "div",
        { staticClass: "customer flex flex-column" },
        [
          _c(
            "dv-decoration-9",
            {
              staticStyle: { height: "14vh", width: "80%" },
              attrs: { dur: 18 },
            },
            [
              _c("count-to", {
                attrs: {
                  "start-val": _vm.oldTotal,
                  "end-val": _vm.newTotal,
                  duration: 3000,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }