<template>
  <div class="demo">
    <div id="takeChargeLongTime" class="chartBar"></div>
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { electricityRate } from "@/api";
// import { isPhone } from "@/api/common";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
} from "./font";

export default {
  components: {
    CountTo,
  },
  props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      name: ["1小时内", "1-2小时", "2-3小时", "3-4小时", "4小时以上"],
      dataList: [
        {
          name: "1小时内",
          value: 656,
        },
        {
          name: "1-2小时",
          value: 588,
        },
        {
          name: "2-3小时",
          value: 572,
        },
        {
          name: "3-4小时",
          value: 553,
        },
        {
          name: "4小时以上",
          value: 1803,
        },

       
      ],
    };
  },
  computed: {
    color() {
      return this.$store.state.color;
    },
  },
  mounted() {
    this.takeChargeLongTime = this.$echarts.init(
      document.getElementById("takeChargeLongTime")
    );
    window.addEventListener("resize", () => {
      this.takeChargeLongTime.resize();
    });
    this.getOrder();
    this.takeChargeLongTimeInter = null;
    this.takeChargeLongTimeInter = setInterval(() => {
      this.getOrder();
    }, 3600000);
  },

  beforeDestroy() {
    clearInterval(this.takeChargeLongTimeInter);
  },
  methods: {
   
    getOptions() {
      // let flag = isPhone();
      let o = {
        title: {
          text: "持续取电时长比例",
          textStyle: {
            align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title2_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#fff",
            lineHeight: h_Font(),
            fontSize: x_Font(),
          },
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color: this.color,
        //  ['#2ec7c9','#b6a2de','#5ab1ef','#ffb980','#37a2da','#32c5e9','#9fe6b8','#ffdb5c','#ff9f7f','#fb7293','#8378ea'],
        legend: {
          bottom: x_Font(),
          itemWidth: w_Font(),
          itemHeight: h_Font(),
          textStyle: {
            color: "#fff",
            lineHeight: line_h_Font(),
            fontSize: x_Font(),
          },
          data: this.name,
        },
        series: [
          {
            name: "取电时长",
            type: "pie",
            // radius: "50%",
            // radius: ["35%", "50%"],
            // center: ["50%", "45%"],
            radius: ["25%", "38%"],
            center: ["50%", "45%"],
            label: {
              show: true,
              fontSize: x_Font(),
            },
            labelLine: {
              length: h_Font(),
            },
            data: this.dataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      return o;
    },
    async getOrder() {
      try {
        let res = await electricityRate(this.roleType);
        this.name = [];
        this.dataList = [];
        res.data.data.forEach((item) => {
          this.name.push(item.electricTime);
          this.dataList.push({
            name: item.electricTime,
            value: item.count,
          });
        });
      } catch (error) {}
      this.initLine();
    },
    initLine() {
      this.takeChargeLongTime.setOption(this.getOptions());
    },
    resize() {
      this.takeChargeLongTime.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}
</style>